/**
 * Load image before we can render it in canvas
 */
export function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    var image = new Image()

    image.onload = function () {
      resolve(image)
    }

    image.onerror = function () {
      reject()
    }

    image.src = src
  })
}
