export function getScaleRatio() {
  return window.devicePixelRatio ?? 1
}

export function retinafy(
  canvas: HTMLCanvasElement | undefined | null,
  context: CanvasRenderingContext2D | undefined,
  width: number,
  height: number
) {
  if (!canvas || !context) return

  const ratio = getScaleRatio()

  canvas.width = width * ratio
  canvas.height = height * ratio
  canvas.style.width = width + 'px'
  canvas.style.height = height + 'px'
  context.scale(ratio, ratio)

  return ratio
}
