import logoBase64 from './assets/logo.png'
import { getTextFontFamily } from './getTextFontFamily'
import { isFirefoxDesktop } from './isMobile'
import { loadImage } from './loadImage'
import { getScaleRatio } from './retinafy'

export async function drawShareMap(canvasEl: HTMLCanvasElement, text: string[]) {
  const fontFamily = getTextFontFamily()

  const ratio = getScaleRatio()
  const width = canvasEl.width / ratio
  const height = canvasEl.height / ratio

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d', isFirefoxDesktop() ? { willReadFrequently: true } : undefined)!
  canvas.width = width
  canvas.height = height + 22

  // Background
  context.fillStyle = '#fff'
  context.fillRect(0, 0, width, height)

  // Logo
  const logo = await loadImage(logoBase64)
  context.drawImage(logo, 2, 4)

  // Labels
  const label = text.map((t) => t.toUpperCase())
  let x = width
  const y = 11

  context.textBaseline = 'middle'
  context.textAlign = 'right'
  context.font = '9pt ' + fontFamily
  for (let i = label.length - 1; i >= 0; i--) {
    const text = label[i]
    let textWidth = context.measureText(text).width

    context.fillStyle = '#4c5261'
    context.fillText(text, x, y)

    if (i > 0) {
      const divider = '  •  '
      context.fillStyle = '#5faaf4'
      context.fillText(divider, x - textWidth, y)
      textWidth = textWidth + context.measureText(divider).width
    }

    x = x - textWidth
  }

  context.drawImage(canvasEl, 0, 0, width * ratio, height * ratio, 0, 22, width, height)

  return canvas.toDataURL()
}
