import { UseMutationResult } from '@tanstack/react-query'
import * as React from 'react'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'

import { Button } from '../../../main/components/button'
import { CopyToClipboard } from '../../../main/components/copy-to-clipboard'
import {
  Dialog,
  DialogBody,
  DialogDismiss,
  DialogFooter,
  DialogHeader,
  useDialogState,
} from '../../../main/components/dialog'
import { ErrorView } from '../../../main/components/error-view'
import { Loading } from '../../../main/components/loading'
import { Heading } from '../../../main/components/typography'

interface ShareResponse {
  imgUrl: string
  shareUrl: string
}

interface ShareProps<ResponseType> {
  state: UseMutationResult<ResponseType | undefined, any, any>
  onShareClick: () => void
  className?: string
  disabled?: boolean
}

export function Share<ResponseType extends ShareResponse>(props: ShareProps<ResponseType>) {
  const dialogState = useDialogState()

  return (
    <>
      <Button
        leftContent="share"
        theme="transparent"
        className={props.className}
        onClick={() => {
          props.onShareClick()
          dialogState.show()
        }}
      >
        Share map
      </Button>

      <Dialog state={dialogState} aria-label="Share map">
        <DialogHeader className="text-sm">Share map</DialogHeader>
        <DialogBody className="flex w-145 max-w-full flex-col">
          <ShareDialogBody isError={props.state.isError} isLoading={props.state.isLoading} data={props.state.data} />
        </DialogBody>
        <DialogFooter>
          <Button as={DialogDismiss}>Close</Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

interface ShareDialogBodyProps<ResponseType> {
  isError: boolean
  isLoading: boolean
  data?: ResponseType
}

function ShareDialogBody<ResponseType extends ShareResponse>(props: ShareDialogBodyProps<ResponseType>) {
  if (props.isLoading) {
    return (
      <div className="flex h-110 items-center justify-center">
        <Loading />
      </div>
    )
  }

  if (props.isError || !props.data) {
    return (
      <div className="h-110">
        <ErrorView
          showRefresh={false}
          title="Map share failed"
          message={
            <>
              There’s been an error sharing the map.
              <br />
              Please try sharing the map again and if the problem persists, please contact support.
            </>
          }
        />
      </div>
    )
  }

  return (
    <>
      <img alt={document.title} src={props.data.imgUrl} className="w-full" />
      <div className="mt-4">
        <CopyToClipboard
          label={
            <Heading level={5} className="m-0">
              Copy and paste link in email, website, or forum
            </Heading>
          }
          text={props.data.shareUrl}
          bottomLeftContent={
            <>
              <FacebookShareButton url={props.data.shareUrl}>
                <Button as="span" size="small" contentClass="flex items-center">
                  <FacebookIcon size={16} round={true} className="mr-1" />
                  <span>Share</span>
                </Button>
              </FacebookShareButton>
              <TwitterShareButton url={props.data.shareUrl} via="FINVIZ_com" title={document.title} className="ml-1">
                <Button as="span" size="small" contentClass="flex items-center">
                  <TwitterIcon size={16} round={true} className="mr-1" />
                  <span>Twitter</span>
                </Button>
              </TwitterShareButton>
            </>
          }
        />
      </div>
    </>
  )
}
