import { ScaleId } from '../maps/types'

export interface GradientDefinition {
  colors: string[]
  nullColor: string
}

const redGreenGradientSmall = {
  colors: [
    '#e01427',
    '#ce252b',
    '#bb2f30',
    '#aa3534',
    '#993938',
    '#853c3c',
    '#743d3f',
    '#414554',
    '#3e5f50',
    '#3f704f',
    '#3f814d',
    '#3c924a',
    '#36a446',
    '#2bb642',
    '#12c93b',
  ],
  nullColor: '#2f323d',
}
export const redGreenGradient = {
  colors: [
    '#f63538',
    '#ee373a',
    '#e6393b',
    '#df3a3d',
    '#d73c3f',
    '#ce3d41',
    '#c73e43',
    '#bf4045',
    '#b64146',
    '#ae4248',
    '#a5424a',
    '#9d434b',
    '#94444d',
    '#8b444e',
    '#824450',
    '#784551',
    '#6f4552',
    '#644553',
    '#5a4554',
    '#4f4554',
    '#414554',
    '#3f4c53',
    '#3d5451',
    '#3b5a50',
    '#3a614f',
    '#38694f',
    '#366f4e',
    '#35764e',
    '#347d4e',
    '#32844e',
    '#31894e',
    '#31904e',
    '#30974f',
    '#2f9e4f',
    '#2fa450',
    '#2faa51',
    '#2fb152',
    '#2fb854',
    '#30be56',
    '#30c558',
    '#30cc5a',
  ],
  nullColor: '#2f323d',
}
const greenRedGradient = { colors: redGreenGradient.colors.slice().reverse(), nullColor: redGreenGradient.nullColor }
const redGradient = { colors: redGreenGradient.colors.slice(0, 22).reverse(), nullColor: redGreenGradient.nullColor }
const greenGradient = { colors: redGreenGradient.colors.slice(21), nullColor: redGreenGradient.nullColor }
const blueGradient = {
  colors: [
    '#3d4251',
    '#3d4250',
    '#3d4250',
    '#3e4251',
    '#3e4251',
    '#3d4350',
    '#3d4350',
    '#3d4350',
    '#3e4351',
    '#3e4350',
    '#3d4351',
    '#3d4352',
    '#3f4353',
    '#3f4353',
    '#3f4453',
    '#3f4554',
    '#3e4354',
    '#3e4354',
    '#3f4454',
    '#3f4454',
    '#3f4454',
    '#3f4454',
    '#3f4454',
    '#3f4555',
    '#3f4556',
    '#3f4457',
    '#3f4457',
    '#3f4457',
    '#3f4557',
    '#3f4657',
    '#3f4657',
    '#3f4657',
    '#3f4557',
    '#3e4557',
    '#3e4558',
    '#3f465a',
    '#3f4659',
    '#3f4759',
    '#3f4759',
    '#3f475a',
    '#3f4759',
    '#3f465a',
    '#3f465a',
    '#3f4759',
    '#3f475b',
    '#40475b',
    '#40475b',
    '#40465b',
    '#40465c',
    '#40465d',
    '#40485d',
    '#40485c',
    '#40475c',
    '#40465c',
    '#40465c',
    '#40465c',
    '#40485d',
    '#40485d',
    '#40485e',
    '#40485f',
    '#40495e',
    '#40495e',
    '#40485e',
    '#40485f',
    '#40495e',
    '#40485e',
    '#40485f',
    '#404960',
    '#404960',
    '#404860',
    '#404861',
    '#404a62',
    '#404962',
    '#404962',
    '#404a62',
    '#404a62',
    '#404861',
    '#404961',
    '#404a63',
    '#404a64',
    '#404a65',
    '#404b64',
    '#404b64',
    '#404a65',
    '#404a65',
    '#404a65',
    '#404a65',
    '#404b65',
    '#404b65',
    '#414c65',
    '#424c65',
    '#424b65',
    '#414b66',
    '#414b67',
    '#414b67',
    '#414c67',
    '#424b67',
    '#424c67',
    '#414c67',
    '#414b67',
    '#414c67',
    '#414c67',
    '#414c69',
    '#414c6a',
    '#414c6a',
    '#414c6a',
    '#414d6a',
    '#424e6a',
    '#414d6a',
    '#414d6a',
    '#414d6a',
    '#414d6a',
    '#414d6a',
    '#414d6b',
    '#414d6c',
    '#414e6c',
    '#414f6c',
    '#414e6c',
    '#414d6c',
    '#414d6c',
    '#414e6c',
    '#424e6d',
    '#424e6d',
    '#414f6d',
    '#424f6f',
    '#414e6f',
    '#414e6f',
    '#424f70',
    '#414f6f',
    '#414f6f',
    '#414f6f',
    '#414e6f',
    '#414e6f',
    '#414f6f',
    '#414f6f',
    '#414f71',
    '#414f72',
    '#425073',
    '#425173',
    '#415072',
    '#425173',
    '#425173',
    '#425173',
    '#415072',
    '#415072',
    '#415172',
    '#415173',
    '#415174',
    '#415174',
    '#415074',
    '#415074',
    '#415174',
    '#415074',
    '#415074',
    '#415074',
    '#415175',
    '#415275',
    '#425276',
    '#425277',
    '#415177',
    '#415177',
    '#425278',
    '#425378',
    '#415277',
    '#415378',
    '#415378',
    '#415277',
    '#415378',
    '#42537a',
    '#42537c',
    '#42537b',
    '#41537a',
    '#41527a',
    '#41537a',
    '#41547a',
    '#42547b',
    '#41547a',
    '#41547a',
    '#41547c',
    '#41547c',
    '#41557c',
    '#41557c',
    '#41557c',
    '#41557c',
    '#41557d',
    '#41547d',
    '#41547c',
    '#42557d',
    '#41557d',
    '#41557d',
    '#41557f',
    '#415580',
    '#41567f',
    '#41567f',
    '#415580',
    '#41567f',
    '#415580',
    '#415580',
    '#41557f',
    '#415680',
    '#415680',
    '#415680',
    '#415681',
    '#415681',
    '#415781',
    '#415781',
    '#415681',
    '#415781',
    '#415781',
    '#415781',
    '#415782',
    '#415784',
    '#415784',
    '#425885',
    '#425885',
    '#415784',
    '#415784',
    '#415784',
    '#415784',
    '#425885',
    '#425885',
    '#415885',
    '#415885',
    '#425987',
    '#415887',
    '#415887',
    '#415987',
    '#415887',
    '#425988',
    '#425988',
    '#415887',
    '#415987',
    '#415988',
    '#415988',
    '#415988',
    '#415989',
    '#415989',
    '#415989',
    '#415989',
    '#415989',
    '#415989',
    '#41598a',
    '#405a8b',
    '#405a8c',
    '#405b8c',
    '#405b8c',
    '#405b8c',
    '#405a8c',
    '#405a8c',
    '#405a8c',
    '#405a8c',
    '#405b8c',
    '#415c8d',
    '#415c8e',
    '#405b8e',
    '#415c90',
    '#405b8f',
    '#405c8f',
    '#415d90',
    '#405b8f',
    '#405c8f',
    '#405c8f',
    '#405b8f',
    '#405c8f',
    '#405c90',
    '#405c91',
    '#405c91',
    '#405c91',
    '#405d91',
    '#405e91',
    '#405d91',
    '#415e91',
    '#405e91',
    '#415e91',
    '#415e92',
    '#3f5d94',
    '#3f5d93',
    '#3f5d93',
    '#3f5e94',
    '#405e94',
    '#3f5d94',
    '#3f5d94',
    '#3f5d94',
    '#3f5e94',
    '#3f5e95',
    '#3f5e96',
    '#3f5e96',
    '#3f5e96',
    '#405f97',
    '#405f97',
    '#3f5e96',
    '#3f5e97',
    '#3f5f97',
    '#3f6097',
    '#3f5f97',
    '#3f6097',
    '#3f5f98',
    '#3f5f99',
    '#3e5f99',
    '#3e6199',
    '#3e6099',
    '#3e5f99',
    '#3e5f99',
    '#3e5f98',
    '#3e6099',
    '#3d609a',
    '#3d6099',
    '#3e609b',
    '#3e619c',
    '#3e619c',
    '#3e619c',
    '#3e609b',
    '#3e609c',
    '#3e609c',
    '#3e619b',
    '#3e629c',
    '#3e629c',
    '#3e619c',
    '#3d619d',
    '#3d639f',
    '#3d629f',
    '#3d629f',
    '#3d639f',
    '#3d629f',
    '#3c619e',
    '#3c619e',
    '#3d629f',
    '#3d62a0',
    '#3d63a0',
    '#3d63a0',
    '#3d62a0',
    '#3d63a1',
    '#3d62a0',
    '#3d63a0',
    '#3d64a1',
    '#3d63a0',
    '#3c63a0',
    '#3b63a2',
    '#3c64a3',
    '#3c65a3',
    '#3b63a3',
    '#3b63a3',
    '#3b63a3',
    '#3b63a3',
    '#3c63a3',
    '#3c65a3',
    '#3c65a3',
    '#3b64a4',
    '#3a65a5',
    '#3b66a6',
    '#3c65a6',
    '#3a65a5',
    '#3a64a5',
    '#3a64a5',
    '#3b65a5',
    '#3a65a5',
    '#3a66a6',
    '#3b66a6',
    '#3b66a6',
    '#3a66a8',
    '#3a67a8',
    '#3b67a8',
    '#3b66a8',
    '#3b66a8',
    '#3b66a8',
    '#3b67a8',
    '#3a66a8',
    '#3966a9',
    '#3967a9',
    '#3a67aa',
    '#3a67ab',
    '#3967ab',
    '#3a68ab',
    '#3a68ab',
    '#3968ab',
    '#3966aa',
    '#3966aa',
    '#3968ab',
    '#3968ab',
    '#3867ac',
    '#3867ac',
    '#3868ac',
    '#3869ad',
    '#3969ad',
    '#3968ad',
    '#3868ac',
    '#3868ac',
    '#3869ae',
    '#3868ad',
    '#3868ad',
    '#3769af',
    '#3869b0',
    '#3769af',
    '#3769af',
    '#386ab0',
    '#386ab0',
    '#376aaf',
    '#376aaf',
    '#376ab0',
    '#366ab1',
    '#366ab2',
    '#366ab2',
    '#366ab2',
    '#366ab3',
    '#366bb3',
    '#366ab2',
    '#3669b2',
    '#366ab2',
    '#366ab2',
    '#366ab2',
    '#356bb3',
    '#346bb4',
    '#356cb4',
    '#356cb4',
    '#346bb4',
    '#356cb4',
    '#356cb5',
    '#356cb5',
    '#356cb5',
    '#346cb5',
    '#346cb5',
    '#346cb6',
    '#336db7',
    '#326cb7',
    '#326db7',
    '#336db7',
    '#336cb7',
    '#326bb6',
    '#326cb6',
    '#326db7',
    '#326db8',
    '#316dba',
    '#316eb9',
    '#316eb9',
    '#326eba',
    '#316eba',
    '#316db9',
    '#316db9',
    '#316eb9',
    '#306eba',
    '#306dba',
    '#306dba',
    '#306ebb',
    '#306dbb',
    '#306dbc',
    '#306fbc',
    '#306fbc',
    '#306ebb',
    '#306eba',
    '#306fbc',
    '#2f6fbd',
    '#2d6ebe',
    '#2d6ebe',
    '#2d6fbe',
    '#2e70be',
    '#2e70be',
    '#2e6fbe',
    '#2e6fbe',
    '#2e6fbe',
    '#2c6fc0',
    '#2d70c0',
    '#2d70c0',
    '#2b70c1',
    '#2b70c1',
    '#2c70c1',
    '#2c70c1',
    '#2b71c1',
    '#2c70c1',
    '#2c70c1',
    '#2c71c1',
    '#2c71c1',
    '#2a72c3',
    '#2971c3',
    '#2a71c2',
    '#2972c3',
    '#2a71c3',
    '#2a71c3',
    '#2972c3',
    '#2972c3',
    '#2972c3',
    '#2872c5',
    '#2872c6',
    '#2872c5',
    '#2773c6',
    '#2773c6',
    '#2772c6',
    '#2772c5',
    '#2672c5',
    '#2673c5',
    '#2673c5',
    '#2673c7',
    '#2472c8',
    '#2473c8',
    '#2474c8',
    '#2573c9',
    '#2573c9',
    '#2573c9',
    '#2573c9',
    '#2474c8',
    '#2374c9',
    '#2374c9',
    '#2374c9',
    '#2375c9',
    '#2374c9',
    '#2374c9',
    '#2475c9',
    '#2575ca',
    '#2476ca',
    '#2476ca',
    '#2476ca',
    '#2575cb',
    '#2577ca',
    '#2577ca',
    '#2576ca',
    '#2577ca',
    '#2577ca',
    '#2577ca',
    '#2677ca',
    '#2678ca',
    '#2678cb',
    '#2778cb',
    '#2778cb',
    '#2679cb',
    '#2779cb',
    '#2779cb',
    '#2779cb',
    '#2779cb',
    '#2879cb',
    '#287acb',
    '#287acb',
    '#2879cb',
    '#287acb',
    '#287bcb',
    '#287acb',
    '#287acb',
    '#287bcc',
    '#287bcc',
    '#297bcc',
    '#297bcc',
    '#297bcc',
    '#297bcc',
    '#297ccc',
    '#297ccc',
    '#297dcc',
    '#297dcc',
    '#2a7dcc',
    '#2a7dcc',
    '#2a7ecd',
    '#2a7ecd',
    '#2a7ecc',
    '#2a7ecc',
    '#2b7ecd',
    '#2c7ecd',
    '#2c7fcd',
    '#2c7fcd',
    '#2b7ecc',
    '#2b7ecc',
    '#2c7fcd',
    '#2c7fcd',
    '#2c7fcd',
    '#2c7fcd',
    '#2b7fcd',
    '#2b80cd',
    '#2c80ce',
    '#2d80ce',
    '#2d80ce',
    '#2c81cd',
    '#2d82ce',
    '#2d82ce',
    '#2d81ce',
    '#2d82ce',
    '#2d82ce',
    '#2d82cd',
    '#2e82cd',
    '#2e83ce',
    '#2e82ce',
    '#2d82cd',
    '#2d83cd',
    '#2e83cf',
    '#2e84cf',
    '#2e84cf',
    '#2e83ce',
    '#2e83ce',
    '#3084cf',
    '#3085cf',
    '#2f85ce',
    '#3085cf',
    '#2f86ce',
    '#2f86ce',
    '#2f86cf',
    '#2f85cf',
    '#2f86ce',
    '#2f86cf',
    '#2f86cf',
    '#2f87cf',
    '#2f86d0',
    '#2f86d0',
    '#2f86d0',
    '#3087cf',
    '#3088d0',
    '#3088d0',
    '#3087cf',
    '#3088cf',
    '#3188d0',
    '#3188d1',
    '#3088d0',
    '#3089d0',
    '#3089d0',
    '#3088d0',
    '#3188d0',
    '#3189d0',
    '#3289d0',
    '#3289d0',
    '#328ad1',
    '#328ad1',
    '#318ad0',
    '#318ad0',
    '#318ad0',
    '#328bd1',
    '#328ad1',
    '#328ad1',
    '#338bd2',
    '#338bd2',
    '#338cd1',
    '#338cd1',
    '#338cd2',
    '#338cd2',
    '#338dd1',
    '#338dd1',
    '#338dd2',
    '#338dd1',
    '#338ed2',
    '#338ed2',
    '#338ed2',
    '#348ed2',
    '#348ed2',
    '#348ed2',
    '#348fd2',
    '#348fd2',
    '#348fd2',
    '#348fd2',
    '#348fd2',
    '#348fd2',
    '#3390d2',
    '#3391d2',
    '#3491d3',
    '#3490d3',
    '#3491d3',
    '#3491d3',
    '#3591d3',
    '#3591d3',
    '#3691d4',
    '#3690d4',
    '#3590d3',
    '#3592d3',
    '#3592d4',
    '#3592d4',
    '#3592d3',
    '#3593d3',
    '#3592d4',
    '#3592d4',
    '#3592d4',
    '#3593d4',
    '#3694d4',
    '#3695d4',
    '#3694d4',
    '#3693d4',
    '#3693d4',
    '#3695d4',
    '#3695d4',
    '#3695d4',
    '#3796d4',
    '#3796d4',
    '#3695d4',
    '#3695d5',
    '#3696d5',
    '#3696d5',
    '#3696d5',
    '#3695d4',
    '#3696d4',
    '#3797d5',
    '#3796d5',
    '#3797d5',
    '#3898d5',
    '#3898d5',
    '#3797d5',
    '#3798d5',
    '#3899d5',
    '#3899d5',
    '#3899d6',
    '#3799d6',
    '#3799d6',
    '#3799d6',
    '#389ad6',
    '#389ad6',
    '#3799d5',
    '#3799d5',
    '#3799d6',
    '#379ad6',
    '#379ad6',
    '#399ad6',
    '#399ad6',
    '#389bd5',
    '#389bd5',
    '#389bd6',
    '#389bd6',
    '#399cd7',
    '#399bd7',
    '#399cd7',
    '#399dd7',
    '#389cd7',
    '#389cd6',
    '#389dd6',
    '#399ed7',
    '#389dd6',
    '#389dd6',
    '#389fd6',
    '#399fd7',
    '#399ed6',
    '#399ed6',
    '#399fd7',
    '#399fd7',
    '#399fd7',
    '#3aa0d8',
    '#3aa0d8',
    '#3a9fd8',
    '#3a9fd8',
    '#3a9fd8',
    '#3aa0d8',
    '#3aa1d8',
    '#3aa1d8',
    '#3aa1d8',
    '#3aa1d8',
    '#3aa1d8',
    '#3aa2d9',
    '#3aa1d9',
    '#3aa1d8',
    '#39a2d8',
    '#3aa2d9',
    '#3aa3d9',
    '#3ba3d9',
    '#3ba3d9',
    '#3ba2d8',
    '#3aa2d8',
    '#3aa4d8',
    '#3ba4d9',
    '#3aa3d8',
    '#3aa3d9',
    '#3ba5da',
    '#3ba5d9',
    '#3ba4d9',
    '#3ba4d9',
    '#3ba4d9',
    '#3aa5d9',
    '#3ba5da',
    '#3ba6da',
    '#3ba6da',
    '#3ba6da',
    '#3ba6d9',
    '#3ba6d9',
    '#3ba6da',
    '#3aa5da',
    '#3ba6da',
    '#3ca7da',
    '#3ca7da',
    '#3ba7da',
    '#3ba6da',
    '#3ca7da',
    '#3ca8da',
    '#3ca8da',
    '#3ca8da',
    '#3ca9db',
    '#3ca9db',
    '#3ca9da',
    '#3ca8db',
    '#3ba8db',
    '#3baadb',
    '#3caadb',
    '#3ca9db',
    '#3caadb',
    '#3caadb',
    '#3ca9db',
    '#3ca9db',
    '#3ca9db',
    '#3cabdb',
    '#3cabdc',
    '#3caadb',
    '#3cabdb',
    '#3cacdc',
    '#3cacdc',
    '#3cacdc',
    '#3caddc',
    '#3caddc',
    '#3caddc',
    '#3caedc',
    '#3caddd',
    '#3caddd',
    '#3caedc',
    '#3eaedd',
    '#3eaddd',
    '#3daddc',
    '#3daedc',
    '#3daedc',
    '#3dafdd',
    '#3dafdd',
    '#3daedd',
    '#3dafdd',
    '#3dafdd',
    '#3dafdd',
    '#3db0dd',
    '#3db0dd',
    '#3db0dc',
    '#3db0dc',
    '#3db1dd',
    '#3db1dd',
    '#3db0dd',
    '#3db0dd',
    '#3db1dd',
    '#3db1de',
    '#3db1de',
    '#3db1de',
    '#3db2de',
    '#3db2de',
    '#3db2dd',
    '#3db2dd',
    '#3db3dd',
    '#3db2de',
    '#3db3de',
    '#3db4de',
    '#3db3dd',
    '#3db4de',
    '#3db4de',
    '#3db4de',
    '#3db5df',
    '#3db5df',
    '#3db4df',
    '#3db4df',
    '#3db5df',
    '#3db5de',
    '#3db5de',
    '#3db5de',
    '#3db6df',
    '#3db6df',
    '#3db6de',
    '#3db6de',
    '#3db7df',
    '#3db7e0',
    '#3db8e0',
    '#3db8e0',
    '#3db7e0',
    '#3db7df',
    '#3db7df',
    '#3eb7df',
    '#3eb8df',
    '#3eb8df',
    '#3eb9e0',
    '#3eb9e0',
    '#3eb8df',
    '#3eb9df',
    '#3fb9df',
    '#3fb9e0',
    '#3ebae1',
    '#3ebae0',
    '#3ebae0',
    '#3ebae0',
    '#3eb9e0',
    '#3fbae1',
    '#3fbbe1',
    '#3ebae0',
    '#3ebbe1',
    '#3ebae0',
    '#3ebbe0',
    '#3ebce1',
    '#3fbce1',
    '#3ebce0',
    '#3ebce2',
    '#3ebce2',
    '#3ebde2',
    '#3fbde2',
    '#3fbde2',
    '#3ebde1',
    '#3ebee1',
    '#3ebde2',
    '#3fbde2',
    '#3fbde2',
    '#3ebee1',
    '#3ebfe1',
    '#3ebfe1',
    '#3ebfe1',
    '#3fbfe2',
    '#3ebfe1',
    '#3ebee1',
    '#3ebee2',
    '#3ebfe2',
    '#3ebfe2',
    '#3ebfe2',
    '#3ec0e2',
    '#3ec1e2',
    '#3fc0e3',
    '#3fc0e3',
    '#3ec0e2',
    '#3ec1e2',
    '#3ec2e2',
    '#3ec2e2',
    '#3ec2e2',
    '#3ec3e3',
    '#3ec3e3',
    '#3ec3e3',
    '#3ec2e3',
    '#3ec2e3',
    '#3fc3e3',
    '#3fc4e3',
    '#3ec4e3',
    '#3ec3e3',
    '#3ec3e3',
    '#3ec5e4',
    '#3fc5e4',
    '#3fc5e3',
    '#3fc4e4',
    '#3fc5e4',
    '#3ec6e4',
    '#3ec5e4',
    '#3ec4e4',
    '#3ec5e4',
    '#3ec6e4',
    '#3ec6e4',
    '#3ec6e4',
    '#3ec6e4',
    '#3ec6e4',
    '#3ec7e4',
    '#3ec6e4',
    '#3ec6e4',
    '#3ec6e4',
    '#3ec7e4',
    '#3dc8e4',
    '#3dc8e4',
    '#3dc7e5',
    '#3dc7e5',
    '#3dc8e5',
    '#3dc8e4',
    '#3dc8e4',
    '#3dc9e5',
    '#3dc9e5',
    '#3dc9e5',
    '#3dc9e5',
    '#3dc9e5',
    '#3dc9e4',
    '#3dc9e5',
    '#3dcae6',
    '#3dcbe6',
    '#3dcae6',
    '#3dcae6',
    '#3dcae6',
    '#3dcbe6',
    '#3dcce6',
    '#3ecce6',
    '#3ecce6',
    '#3dcbe6',
    '#3dcce6',
    '#3dcce6',
    '#3dcce6',
    '#3dcce5',
    '#3dcce5',
    '#3dcce6',
    '#3dcde7',
    '#3dcde7',
    '#3dcde7',
    '#3dcfe7',
    '#3dcfe7',
    '#3dcfe7',
    '#3dcee7',
    '#3dcee6',
    '#3dcee6',
    '#3dcfe7',
    '#3dcfe7',
    '#3dcfe6',
    '#3dcfe6',
    '#3dd0e8',
    '#3ed1e8',
    '#3ed1e7',
    '#3dd0e7',
    '#3dd0e8',
    '#3dd0e7',
    '#3dd1e7',
    '#3dd1e7',
    '#3dd0e7',
    '#3cd1e8',
    '#3cd2e8',
    '#3cd1e8',
    '#3cd1e7',
    '#3cd1e7',
    '#3cd2e8',
    '#3cd3e9',
    '#3cd2e8',
    '#3cd2e9',
    '#3cd2e9',
    '#3cd3e8',
    '#3cd4e8',
    '#3cd3e8',
    '#3cd3e8',
  ],
  nullColor: '#2f323d',
}
const earningsGradient = {
  colors: [
    '#592d48',
    '#592d49',
    '#5b2d49',
    '#5b2d49',
    '#5b2d49',
    '#5b2e49',
    '#5b2e49',
    '#5b2e4a',
    '#5c2e4a',
    '#5d2f4b',
    '#5c2f4b',
    '#5c2e4b',
    '#5f2f4c',
    '#5f2f4c',
    '#5e2e4c',
    '#5e2e4c',
    '#60304d',
    '#60304d',
    '#5f2f4d',
    '#5f2f4d',
    '#62304f',
    '#62304f',
    '#61304f',
    '#62314f',
    '#62314f',
    '#633150',
    '#633150',
    '#63314f',
    '#63304f',
    '#643150',
    '#643151',
    '#643151',
    '#653152',
    '#653051',
    '#653051',
    '#663152',
    '#673253',
    '#673253',
    '#673253',
    '#673253',
    '#683254',
    '#683254',
    '#683254',
    '#693254',
    '#693354',
    '#6a3354',
    '#6a3354',
    '#6a3355',
    '#6a3356',
    '#6a3456',
    '#6c3456',
    '#6c3356',
    '#6c3457',
    '#6c3457',
    '#6c3457',
    '#6c3457',
    '#6d3458',
    '#6e3559',
    '#6f3559',
    '#6f3459',
    '#6f345a',
    '#70355b',
    '#70365b',
    '#6f355a',
    '#70365b',
    '#71355a',
    '#71355a',
    '#71365b',
    '#72355b',
    '#72365c',
    '#72365d',
    '#72355c',
    '#73355c',
    '#73355d',
    '#73375d',
    '#73375d',
    '#76375e',
    '#76375f',
    '#76375f',
    '#76375f',
    '#76365e',
    '#76365e',
    '#76375f',
    '#763860',
    '#773861',
    '#783860',
    '#783860',
    '#783861',
    '#793861',
    '#793862',
    '#793762',
    '#793761',
    '#7b3962',
    '#7b3962',
    '#7a3962',
    '#7a3962',
    '#7b3963',
    '#7c3964',
    '#7d3a64',
    '#7d3b64',
    '#7d3a65',
    '#7d3a65',
    '#7d3a65',
    '#7e3b65',
    '#803b66',
    '#7f3a66',
    '#7e3a66',
    '#7f3a67',
    '#7f3b67',
    '#803c67',
    '#803c67',
    '#7f3b67',
    '#813c68',
    '#823b69',
    '#823b69',
    '#813b69',
    '#823c69',
    '#823c69',
    '#823c69',
    '#823c69',
    '#843d6a',
    '#843d6a',
    '#843d6a',
    '#843c6b',
    '#863c6c',
    '#853c6c',
    '#853d6c',
    '#863e6c',
    '#863d6c',
    '#873d6c',
    '#873d6c',
    '#873d6d',
    '#893e6e',
    '#8a3e6f',
    '#8a3e6f',
    '#893e6e',
    '#893d6e',
    '#893e6e',
    '#8a3f6f',
    '#8b3f70',
    '#8b3f71',
    '#8b3e70',
    '#8b3e70',
    '#8c3f70',
    '#8c4071',
    '#8c4070',
    '#8c4070',
    '#8d4071',
    '#8d4072',
    '#8d4072',
    '#8f4073',
    '#8f4074',
    '#8f4073',
    '#8f4173',
    '#8f4173',
    '#904173',
    '#904174',
    '#904175',
    '#904175',
    '#914175',
    '#924175',
    '#924275',
    '#924275',
    '#914175',
    '#924277',
    '#934277',
    '#934277',
    '#944278',
    '#954277',
    '#954278',
    '#954278',
    '#954278',
    '#954378',
    '#964378',
    '#964379',
    '#96447a',
    '#96437a',
    '#96437a',
    '#98437a',
    '#98447b',
    '#98457b',
    '#98457b',
    '#98457b',
    '#99447b',
    '#9a457d',
    '#9b457d',
    '#9b457d',
    '#9a447c',
    '#9a457c',
    '#9b457c',
    '#9b457e',
    '#9b467e',
    '#9c467e',
    '#9d467f',
    '#9e467f',
    '#9d467f',
    '#9e467e',
    '#9e477f',
    '#9e4780',
    '#9e477f',
    '#9f4680',
    '#a04680',
    '#a04880',
    '#a04881',
    '#a04881',
    '#a04881',
    '#a24883',
    '#a24883',
    '#a24882',
    '#a24883',
    '#a24883',
    '#a24883',
    '#a24883',
    '#a34983',
    '#a34983',
    '#a34984',
    '#a44985',
    '#a54986',
    '#a54986',
    '#a54986',
    '#a54a85',
    '#a54a85',
    '#a54a85',
    '#a64a86',
    '#a74a87',
    '#a84a88',
    '#a84a88',
    '#a74a87',
    '#a84b87',
    '#a84b88',
    '#a84b88',
    '#a84b88',
    '#a94b89',
    '#aa4b89',
    '#ab4b89',
    '#ab4b89',
    '#ab4c8a',
    '#aa4c8a',
    '#aa4c8a',
    '#ab4c8a',
    '#ad4c8b',
    '#ad4c8b',
    '#ad4c8b',
    '#ad4c8c',
    '#ae4d8d',
    '#ae4c8c',
    '#ae4d8d',
    '#af4e8e',
    '#af4e8d',
    '#b04e8d',
    '#b04e8d',
    '#b04d8e',
    '#b04e8e',
    '#b04d8e',
    '#b04e8e',
    '#b14f8f',
    '#b14e90',
    '#b14e90',
    '#b14f90',
    '#b25090',
    '#b34f90',
    '#b34f90',
    '#b34f90',
    '#b35091',
    '#b44f91',
    '#b55092',
    '#b55092',
    '#b55092',
    '#b55192',
    '#b55092',
    '#b55092',
    '#b55093',
    '#b65094',
    '#b75194',
    '#b85193',
    '#b75093',
    '#b85095',
    '#b85095',
    '#b85295',
    '#b85295',
    '#ba5296',
    '#b95197',
    '#b95197',
    '#ba5297',
    '#ba5297',
    '#ba5296',
    '#ba5296',
    '#bb5397',
    '#bc5297',
    '#bd5298',
    '#bd5499',
    '#bc5499',
    '#bc5499',
    '#bc5499',
    '#bd5499',
    '#be549a',
    '#bf549b',
    '#bf549b',
    '#bf549b',
    '#bf549b',
    '#c0559b',
    '#c0559b',
    '#c0559b',
    '#c0559b',
    '#c0549c',
    '#c0549c',
    '#c2569c',
    '#c2569d',
    '#c2569e',
    '#c2569e',
    '#c4569e',
    '#c4569e',
    '#c4569f',
    '#c3579e',
    '#c3579e',
    '#c5579e',
    '#c658a0',
    '#c5579f',
    '#c4579f',
    '#c557a0',
    '#c757a0',
    '#c657a0',
    '#c658a1',
    '#c759a2',
    '#c758a2',
    '#c758a2',
    '#c858a2',
    '#c959a3',
    '#ca59a3',
    '#c958a2',
    '#c958a2',
    '#c959a3',
    '#ca59a4',
    '#cb59a4',
    '#cb59a4',
    '#cb59a4',
    '#cb5aa5',
    '#cc5ba5',
    '#cc5ba5',
    '#cc5aa5',
    '#cd5ba6',
    '#cd5ba6',
    '#cd5ba6',
    '#ce5ba7',
    '#ce5ca7',
    '#cf5ca7',
    '#cf5ca7',
    '#cf5ca7',
    '#d05ca8',
    '#d05ba9',
    '#d05ba9',
    '#d05ba9',
    '#d15ca9',
    '#d15da9',
    '#d15da9',
    '#d15ca9',
    '#d25caa',
    '#d25dab',
    '#d25eab',
    '#d25eab',
    '#d35eab',
    '#d35dab',
    '#d35dab',
    '#d45eac',
    '#d45dac',
    '#d55ead',
    '#d55ead',
    '#d55dad',
    '#d65eac',
    '#d65fad',
    '#d65fae',
    '#d65fae',
    '#d75fae',
    '#d75fae',
    '#d75fae',
    '#d75fae',
    '#d75faf',
    '#d760b0',
    '#d960b0',
    '#d95fb0',
    '#d961b0',
    '#d961b1',
    '#d961b1',
    '#da61b0',
    '#dc61b2',
    '#dc60b2',
    '#db60b2',
    '#db61b2',
    '#dc61b2',
    '#dd61b3',
    '#dd61b3',
    '#dd62b3',
    '#de62b3',
    '#de63b4',
    '#de63b5',
    '#dd62b4',
    '#de62b4',
    '#df63b5',
    '#e063b5',
    '#e063b5',
    '#e063b6',
    '#e164b6',
    '#e164b6',
    '#e164b6',
    '#e164b7',
    '#e264b7',
    '#e264b7',
    '#e264b7',
    '#e264b8',
    '#e265b8',
    '#e265b8',
    '#e365b8',
    '#e364b8',
    '#e465b9',
    '#e565ba',
    '#e565ba',
    '#e566b9',
    '#e566ba',
    '#e566bb',
    '#e666ba',
    '#e766bb',
    '#e665bb',
    '#e665bb',
    '#e666bb',
    '#e867bc',
    '#e867bc',
    '#e867bc',
    '#e867bc',
    '#e968bd',
    '#e967be',
    '#e968be',
    '#e969bd',
    '#ea68bf',
    '#ea69bf',
    '#ea69bf',
    '#eb69c0',
    '#eb69c0',
    '#eb6ac0',
    '#eb6ac0',
    '#ec6ac1',
    '#ed6ac2',
    '#ec6bc2',
    '#ec6bc2',
    '#ed6bc2',
    '#ed6bc3',
    '#ed6bc3',
    '#ed6bc3',
    '#ee6bc2',
    '#ef6bc3',
    '#ef6cc3',
    '#ef6cc3',
    '#f06cc4',
    '#f06cc5',
    '#f16cc4',
    '#f16cc4',
    '#f16cc5',
    '#f16dc6',
    '#f16dc6',
    '#f36dc6',
    '#f36dc7',
    '#f36dc6',
    '#f36dc7',
    '#f36dc7',
    '#f46dc7',
    '#f56ec7',
    '#f46ec7',
    '#f46ec7',
    '#f46ec8',
    '#f56ec8',
    '#f56fc8',
    '#f66fc8',
    '#f76fc9',
    '#f76fc9',
    '#f76fc9',
    '#f86fca',
    '#f970cb',
    '#f971cb',
    '#f870cb',
    '#f870cb',
    '#f870cc',
    '#f970cd',
    '#fa71cd',
    '#fa71cd',
    '#fa71cd',
    '#fa72ce',
    '#fb71ce',
    '#fb71ce',
    '#fb71ce',
    '#fc72cf',
    '#fb72ce',
    '#fb72ce',
    '#fc72cf',
    '#fd72d0',
    '#fe73d1',
    '#ff73d1',
    '#ff72d1',
    '#fe72d1',
    '#ff73d2',
    '#ff74d2',
    '#fe74d2',
    '#02dfce',
    '#02dece',
    '#02dece',
    '#03dfce',
    '#03dfce',
    '#03dfcd',
    '#03ddcd',
    '#03ddcd',
    '#03ddcc',
    '#02dccb',
    '#02dccb',
    '#03dccc',
    '#03dbcb',
    '#03dbca',
    '#03dbca',
    '#03dbca',
    '#03daca',
    '#04dbcb',
    '#04dacb',
    '#03d9ca',
    '#03d8c9',
    '#04d9c9',
    '#04dac9',
    '#04d9c9',
    '#04d9c8',
    '#04d7c7',
    '#04d6c7',
    '#04d7c8',
    '#05d7c7',
    '#05d6c7',
    '#05d6c7',
    '#04d6c7',
    '#05d5c6',
    '#05d5c6',
    '#05d5c6',
    '#05d5c5',
    '#06d4c5',
    '#05d4c5',
    '#05d4c5',
    '#06d4c5',
    '#06d3c4',
    '#06d2c4',
    '#06d2c4',
    '#06d2c3',
    '#06d2c3',
    '#07d2c2',
    '#07d0c1',
    '#07d0c2',
    '#07d0c1',
    '#07cfc1',
    '#07cfc1',
    '#07d0c1',
    '#09cfc0',
    '#08cfc0',
    '#09cfc0',
    '#0acec1',
    '#0acec0',
    '#09cdbf',
    '#09cdbf',
    '#0acebf',
    '#0accbe',
    '#0bccbf',
    '#0bccbf',
    '#0bccbe',
    '#0bcbbe',
    '#0bcbbe',
    '#0bcbbd',
    '#0ccabc',
    '#0ccbbd',
    '#0dcbbd',
    '#0dcabc',
    '#0cc9bb',
    '#0dc8ba',
    '#0ec9bb',
    '#0ec9bb',
    '#0dc8ba',
    '#0ec8bb',
    '#0dc7ba',
    '#0ec7ba',
    '#0fc7ba',
    '#0ec6b9',
    '#0fc6b9',
    '#0fc6b9',
    '#0fc5b8',
    '#0fc6b8',
    '#0fc6b8',
    '#0fc4b8',
    '#10c4b7',
    '#10c4b7',
    '#10c3b6',
    '#10c3b6',
    '#10c3b6',
    '#12c2b6',
    '#11c2b6',
    '#11c2b6',
    '#12c3b6',
    '#12c2b5',
    '#12c1b5',
    '#12c0b5',
    '#13c0b4',
    '#13c1b4',
    '#13c0b3',
    '#13bfb3',
    '#13c0b4',
    '#13bfb2',
    '#13beb3',
    '#13beb3',
    '#13bfb2',
    '#14beb1',
    '#14beb1',
    '#14beb1',
    '#14bdb1',
    '#14bdb1',
    '#15bdb1',
    '#16bdb1',
    '#15bcb0',
    '#16baaf',
    '#16bab0',
    '#16bbb0',
    '#15bbaf',
    '#15b9ae',
    '#15b9ae',
    '#16baae',
    '#17baae',
    '#17b9ae',
    '#17b8ae',
    '#17b8ac',
    '#17b8ac',
    '#18b8ac',
    '#18b7ab',
    '#18b7ab',
    '#18b7ab',
    '#19b7ac',
    '#18b7ac',
    '#18b5ac',
    '#18b5ab',
    '#18b5aa',
    '#19b4aa',
    '#1ab4aa',
    '#1ab5aa',
    '#19b3a9',
    '#19b3a9',
    '#19b4a9',
    '#19b4a9',
    '#19b2a8',
    '#1ab3a8',
    '#1ab3a8',
    '#1ab2a7',
    '#1bb1a7',
    '#1ab0a7',
    '#1ab0a7',
    '#1ab0a7',
    '#1bb1a6',
    '#1bb1a6',
    '#1bafa6',
    '#1bafa6',
    '#1cafa5',
    '#1cafa5',
    '#1cafa5',
    '#1caea4',
    '#1caea4',
    '#1caea4',
    '#1caea4',
    '#1cada3',
    '#1caca2',
    '#1caca2',
    '#1caca2',
    '#1caca2',
    '#1eaba2',
    '#1eaca2',
    '#1eaca2',
    '#1eaba2',
    '#1daaa1',
    '#1ea9a1',
    '#1ea9a1',
    '#1daaa0',
    '#1ea89f',
    '#1fa9a0',
    '#1fa8a0',
    '#1ea79f',
    '#1fa79e',
    '#1fa89f',
    '#1fa79e',
    '#1ea69d',
    '#1ea79d',
    '#1ea79c',
    '#1ea59c',
    '#1ea59c',
    '#20a59c',
    '#1fa59c',
    '#1fa59c',
    '#20a49b',
    '#20a49c',
    '#1fa49c',
    '#1fa49a',
    '#1fa39a',
    '#20a29a',
    '#20a39a',
    '#20a39a',
    '#20a29a',
    '#21a199',
    '#21a099',
    '#21a099',
    '#21a198',
    '#21a098',
    '#219f98',
    '#219f98',
    '#21a098',
    '#219f97',
    '#219f97',
    '#219e97',
    '#219d96',
    '#219d97',
    '#229d96',
    '#229d96',
    '#229d96',
    '#229c95',
    '#239d95',
    '#239d95',
    '#239c95',
    '#229a94',
    '#229a93',
    '#229a93',
    '#239b93',
    '#229a93',
    '#239892',
    '#239892',
    '#239992',
    '#249991',
    '#249991',
    '#249791',
    '#239791',
    '#249791',
    '#249791',
    '#249791',
    '#239690',
    '#239590',
    '#24968f',
    '#24968f',
    '#23958f',
    '#24958e',
    '#24948e',
    '#24948e',
    '#24958e',
    '#25938d',
    '#25938d',
    '#25938d',
    '#24938c',
    '#24928c',
    '#25928c',
    '#25928c',
    '#25928c',
    '#25918c',
    '#25908b',
    '#25908b',
    '#25908b',
    '#248f8a',
    '#258f8a',
    '#258f8a',
    '#25908b',
    '#258f8a',
    '#248e89',
    '#258e89',
    '#268e89',
    '#268e88',
    '#268e88',
    '#268d88',
    '#268c88',
    '#268c86',
    '#268c86',
    '#268c87',
    '#268c87',
    '#268b86',
    '#268a87',
    '#268a86',
    '#268a85',
    '#268a86',
    '#268a86',
    '#268884',
    '#268884',
    '#268884',
    '#278783',
    '#278783',
    '#268883',
    '#278682',
    '#278682',
    '#278682',
    '#278682',
    '#278681',
    '#278681',
    '#278581',
    '#278480',
    '#278580',
    '#278480',
    '#278381',
    '#278481',
    '#27837f',
    '#288480',
    '#288380',
    '#27827f',
    '#27827f',
    '#27817e',
    '#27817e',
    '#27817e',
    '#27807d',
    '#27807d',
    '#27807d',
    '#28807d',
    '#27807c',
    '#277f7b',
    '#277f7b',
    '#277f7b',
    '#287e7b',
    '#287d7a',
    '#287d7a',
    '#297e7b',
    '#297d7b',
    '#297c7b',
    '#297c7b',
    '#297d7b',
    '#287b79',
    '#287c79',
    '#287b79',
    '#297a79',
    '#297a78',
    '#297b78',
    '#297b78',
    '#287a77',
    '#287976',
    '#287876',
    '#287876',
    '#287876',
    '#287775',
    '#297876',
    '#297976',
    '#297776',
    '#287675',
    '#297776',
    '#297775',
    '#287674',
    '#297574',
    '#297574',
    '#297574',
    '#297474',
    '#287472',
    '#297472',
    '#297472',
    '#297472',
    '#297271',
    '#287271',
    '#287271',
    '#297271',
    '#297271',
    '#287171',
    '#28716f',
    '#29716f',
    '#28706f',
    '#297070',
    '#297070',
    '#296f6f',
    '#296f6f',
    '#286e6e',
    '#286e6e',
    '#286f6e',
    '#296e6e',
    '#286d6d',
    '#286d6d',
    '#296d6d',
    '#296e6d',
    '#296e6d',
    '#296c6b',
    '#286c6b',
    '#286c6b',
    '#296c6b',
    '#296a6b',
    '#286a6a',
    '#286a6a',
    '#296969',
    '#2a6969',
    '#296969',
    '#296869',
    '#2a6969',
    '#2a6969',
    '#2a6969',
    '#2a6868',
    '#296768',
    '#296768',
    '#2a6767',
    '#2a6767',
    '#2a6767',
    '#2a6567',
    '#296566',
    '#286666',
    '#286565',
    '#286565',
    '#296565',
    '#286465',
    '#296464',
    '#296464',
    '#286364',
    '#286263',
    '#286263',
    '#286263',
    '#286163',
    '#286062',
    '#286062',
    '#286162',
    '#296163',
    '#285f62',
    '#296062',
    '#296062',
    '#295f61',
    '#296061',
    '#285f60',
    '#285d5f',
    '#285d5f',
    '#285d60',
    '#285c5f',
    '#285c5e',
    '#285c5f',
    '#295b5e',
    '#285b5d',
    '#285b5d',
    '#285c5d',
    '#295c5d',
    '#285b5c',
    '#285b5c',
    '#285b5c',
    '#285a5d',
    '#28595d',
    '#28595b',
    '#28595b',
    '#29585c',
    '#28585b',
    '#28585a',
    '#28575a',
    '#29585b',
    '#29585b',
    '#29575a',
    '#295659',
    '#285558',
    '#285658',
    '#285658',
    '#295558',
    '#295557',
    '#285457',
    '#285357',
    '#285457',
    '#285356',
    '#295456',
    '#295356',
    '#285256',
    '#295256',
    '#285255',
    '#285255',
    '#295255',
    '#295155',
    '#284f54',
    '#274f54',
    '#275054',
    '#275054',
    '#275054',
    '#274e52',
    '#274e52',
    '#284e52',
    '#274d51',
    '#274d51',
    '#274e52',
    '#274c50',
    '#274c50',
    '#274e51',
    '#284e51',
    '#274c50',
    '#284c50',
    '#284b50',
    '#274a50',
    '#284a50',
    '#284a50',
    '#28494e',
    '#27484e',
    '#274a4e',
    '#274a4d',
    '#27494d',
    '#28484e',
    '#27474c',
    '#26484d',
    '#26484d',
    '#26484c',
    '#25464b',
    '#26464b',
    '#26474b',
    '#26474b',
    '#26454a',
    '#26454a',
    '#26464a',
    '#26464a',
    '#254349',
    '#264349',
    '#264349',
    '#26444a',
  ],
  nullColor: '#414554',
}

const redGreenGradientBubbles = {
  colors: [
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f63538',
    '#f53538',
    '#f53538',
    '#f53538',
    '#f53538',
    '#f53538',
    '#f53538',
    '#f53538',
    '#f53538',
    '#f43538',
    '#f43538',
    '#f43538',
    '#f43538',
    '#f43538',
    '#f43538',
    '#f33538',
    '#f33538',
    '#f33538',
    '#f33538',
    '#f33538',
    '#f33538',
    '#f33538',
    '#f33538',
    '#f33538',
    '#f23538',
    '#f23538',
    '#f23538',
    '#f23538',
    '#f13538',
    '#f13538',
    '#f13538',
    '#f13538',
    '#f03538',
    '#f03538',
    '#f03538',
    '#f03538',
    '#f03538',
    '#f03538',
    '#ef3538',
    '#ef3538',
    '#ef3538',
    '#ef3538',
    '#ef3538',
    '#ef3538',
    '#ee3639',
    '#ee3639',
    '#ee3639',
    '#ed3639',
    '#ed3639',
    '#ed3639',
    '#ec3639',
    '#ec3639',
    '#ec3639',
    '#eb3639',
    '#eb3639',
    '#eb3639',
    '#eb3639',
    '#eb3639',
    '#ea3639',
    '#ea3639',
    '#ea3639',
    '#e93639',
    '#e93639',
    '#e83538',
    '#e83538',
    '#e83538',
    '#e73538',
    '#e73538',
    '#e73538',
    '#e63538',
    '#e63639',
    '#e63639',
    '#e63639',
    '#e63639',
    '#e53639',
    '#e53639',
    '#e53639',
    '#e53639',
    '#e53639',
    '#e53639',
    '#e43639',
    '#e43639',
    '#e43639',
    '#e33639',
    '#e33639',
    '#e33639',
    '#e23639',
    '#e23639',
    '#e23639',
    '#e13639',
    '#e13639',
    '#e13639',
    '#e03639',
    '#e0363a',
    '#e0363a',
    '#e0363a',
    '#df363a',
    '#df363a',
    '#df363a',
    '#de363a',
    '#de363a',
    '#de363a',
    '#de363a',
    '#de363a',
    '#de363a',
    '#dd363a',
    '#dd373b',
    '#dd373b',
    '#dc373b',
    '#dc373b',
    '#dc373b',
    '#db373b',
    '#db373b',
    '#db373b',
    '#da373b',
    '#da373d',
    '#da373d',
    '#d9373d',
    '#d9373d',
    '#d9373d',
    '#d9373d',
    '#d9373d',
    '#d8373e',
    '#d8373e',
    '#d7383e',
    '#d7383e',
    '#d7383e',
    '#d6383e',
    '#d6383e',
    '#d5383e',
    '#d5383e',
    '#d4383e',
    '#d4383e',
    '#d4383f',
    '#d3383f',
    '#d3383f',
    '#d3393f',
    '#d33940',
    '#d23940',
    '#d23940',
    '#d13940',
    '#d13941',
    '#d03941',
    '#d03941',
    '#cf3941',
    '#cf3942',
    '#cf3942',
    '#cf3a42',
    '#ce3a42',
    '#cd3a43',
    '#cd3a43',
    '#cc3943',
    '#cc3943',
    '#cb3944',
    '#cb3944',
    '#ca3944',
    '#ca3944',
    '#c93a45',
    '#c93a45',
    '#c83a45',
    '#c73a45',
    '#c73a45',
    '#c53a45',
    '#c53a45',
    '#c43a45',
    '#c43a46',
    '#c43a46',
    '#c43c46',
    '#c33c47',
    '#c33c47',
    '#c23c47',
    '#c23c47',
    '#c13c48',
    '#c13c48',
    '#c03c48',
    '#c03c48',
    '#bf3c48',
    '#bf3d4a',
    '#be3d4a',
    '#be3d4a',
    '#bd3d4a',
    '#bd3d4b',
    '#bc3d4b',
    '#bc3d4b',
    '#bb3d4b',
    '#bb3d4b',
    '#bb3d4c',
    '#bb3d4c',
    '#ba3d4c',
    '#ba3e4c',
    '#b83e4c',
    '#b83e4c',
    '#b83e4c',
    '#b73e4c',
    '#b73e4c',
    '#b63e4c',
    '#b63e4c',
    '#b53e4d',
    '#b53e4d',
    '#b53e4d',
    '#b43e4d',
    '#b43e4d',
    '#b33e4d',
    '#b33f4d',
    '#b23f4e',
    '#b23f4e',
    '#b23f4e',
    '#b13f4e',
    '#b13f4e',
    '#b03e4e',
    '#b03e4e',
    '#b03e4f',
    '#b03e4f',
    '#b03e4f',
    '#ae3e4f',
    '#ae3e4f',
    '#ad3e4f',
    '#ad3e4f',
    '#ac3e4f',
    '#ac3f50',
    '#ab3f50',
    '#ab3f50',
    '#aa3f50',
    '#aa3f50',
    '#a93f50',
    '#a93f50',
    '#a73f50',
    '#a73f50',
    '#a63f50',
    '#a63f51',
    '#a53f51',
    '#a53f51',
    '#a43f50',
    '#a43f50',
    '#a43f50',
    '#a43f50',
    '#a24050',
    '#a24050',
    '#a14050',
    '#a04051',
    '#a04051',
    '#9f4051',
    '#9f4051',
    '#9e4051',
    '#9c4051',
    '#9c4051',
    '#9b4051',
    '#9a4051',
    '#9a4053',
    '#994053',
    '#994153',
    '#984153',
    '#974153',
    '#974153',
    '#964153',
    '#954153',
    '#954153',
    '#934054',
    '#934054',
    '#924054',
    '#914054',
    '#914054',
    '#904053',
    '#8e4053',
    '#8e4153',
    '#8d4153',
    '#8d4153',
    '#8d4154',
    '#8b4154',
    '#8b4154',
    '#8a4154',
    '#894154',
    '#894154',
    '#884154',
    '#884154',
    '#864154',
    '#864154',
    '#854154',
    '#844155',
    '#844255',
    '#824255',
    '#824255',
    '#824255',
    '#824255',
    '#814255',
    '#814255',
    '#804255',
    '#804255',
    '#7e4255',
    '#7e4255',
    '#7d4255',
    '#7d4256',
    '#7c4256',
    '#7c4256',
    '#7b4256',
    '#7b4256',
    '#7b4256',
    '#794256',
    '#794256',
    '#784256',
    '#784356',
    '#774256',
    '#774256',
    '#774256',
    '#764256',
    '#764256',
    '#764256',
    '#754256',
    '#754256',
    '#744255',
    '#744255',
    '#744255',
    '#734255',
    '#734255',
    '#734255',
    '#724255',
    '#724255',
    '#724255',
    '#714255',
    '#714255',
    '#6f4255',
    '#6f4255',
    '#6f4255',
    '#6e4255',
    '#6e4255',
    '#6e4255',
    '#6d4255',
    '#6d4255',
    '#6d4255',
    '#6c4255',
    '#6c4255',
    '#6c4255',
    '#6b4155',
    '#6b4155',
    '#694155',
    '#694155',
    '#694155',
    '#684155',
    '#684155',
    '#684155',
    '#684155',
    '#684155',
    '#674155',
    '#674155',
    '#674155',
    '#664154',
    '#664154',
    '#654154',
    '#654154',
    '#654154',
    '#634154',
    '#634154',
    '#624154',
    '#624154',
    '#614154',
    '#614154',
    '#614154',
    '#604054',
    '#604054',
    '#5f4054',
    '#5f4054',
    '#5f4054',
    '#5e4054',
    '#5e4054',
    '#5d4054',
    '#5d4054',
    '#5d4054',
    '#5c4053',
    '#5c4053',
    '#5c4053',
    '#5b4053',
    '#5b4053',
    '#5a4053',
    '#5a4053',
    '#5a4053',
    '#593f53',
    '#593f53',
    '#593f53',
    '#583f53',
    '#583f53',
    '#583f53',
    '#573f53',
    '#573e53',
    '#573e53',
    '#563e53',
    '#563e53',
    '#563e53',
    '#563e53',
    '#553e52',
    '#553e52',
    '#553e52',
    '#543e52',
    '#543e52',
    '#543e52',
    '#543e52',
    '#543e52',
    '#533e52',
    '#533e52',
    '#533e52',
    '#533e52',
    '#533e52',
    '#523e51',
    '#523e51',
    '#523e51',
    '#523e51',
    '#523e51',
    '#513e51',
    '#513e51',
    '#513e51',
    '#513e51',
    '#513e51',
    '#513e51',
    '#513e51',
    '#513e51',
    '#503e51',
    '#503e51',
    '#503e51',
    '#503e51',
    '#503e51',
    '#503e51',
    '#503e51',
    '#503e51',
    '#503e51',
    '#503f51',
    '#503f51',
    '#4f3f51',
    '#4f3f51',
    '#4f3f51',
    '#4d3f51',
    '#4d3f51',
    '#4d4151',
    '#4b4151',
    '#4b4151',
    '#4a4152',
    '#494152',
    '#484253',
    '#484253',
    '#474253',
    '#474253',
    '#464353',
    '#454353',
    '#454353',
    '#444454',
    '#444454',
    '#434454',
    '#434454',
    '#424454',
    '#424554',
    '#424554',
    '#424554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#414554',
    '#404654',
    '#404654',
    '#404654',
    '#404653',
    '#404653',
    '#3f4653',
    '#3f4753',
    '#3f4753',
    '#3e4753',
    '#3e4753',
    '#3e4852',
    '#3d4852',
    '#3d4852',
    '#3c4951',
    '#3c4951',
    '#3b4951',
    '#3b4a51',
    '#3b4a50',
    '#3b4a50',
    '#3a4c50',
    '#394c50',
    '#394c50',
    '#394c50',
    '#394c50',
    '#394c50',
    '#384c4f',
    '#384c4f',
    '#384c4f',
    '#384d50',
    '#384d50',
    '#384d50',
    '#384d50',
    '#384d50',
    '#384d50',
    '#384d50',
    '#384d50',
    '#384d50',
    '#384e50',
    '#364e50',
    '#364e4f',
    '#364e4f',
    '#364e4f',
    '#364e4f',
    '#364e4f',
    '#364f4f',
    '#364f4f',
    '#354f4f',
    '#354f4f',
    '#354f4f',
    '#354f4f',
    '#35514f',
    '#35514f',
    '#35514f',
    '#34514f',
    '#34514f',
    '#34524f',
    '#34524f',
    '#34524f',
    '#34524f',
    '#33524e',
    '#33534e',
    '#33534e',
    '#33534e',
    '#32534e',
    '#32544e',
    '#32544e',
    '#32544e',
    '#32544e',
    '#31554e',
    '#31554e',
    '#31554e',
    '#31564d',
    '#31564d',
    '#31564d',
    '#31564d',
    '#31574d',
    '#30574d',
    '#30574d',
    '#30584d',
    '#30584d',
    '#2f584d',
    '#2f594d',
    '#2f594d',
    '#2f594c',
    '#2e5a4c',
    '#2e5a4c',
    '#2e5a4c',
    '#2d5b4c',
    '#2d5b4c',
    '#2d5b4c',
    '#2d5b4c',
    '#2c5c4c',
    '#2c5c4c',
    '#2c5c4c',
    '#2b5d4b',
    '#2b5d4b',
    '#2b5d4b',
    '#2b5e4b',
    '#2a5e4b',
    '#2a5e4b',
    '#2a5f4b',
    '#2a5f4b',
    '#2a5f4b',
    '#2a604b',
    '#2a604b',
    '#2a604b',
    '#29624b',
    '#29624b',
    '#29624b',
    '#29624b',
    '#29634b',
    '#29634b',
    '#29634b',
    '#29644b',
    '#28644b',
    '#28644b',
    '#28644b',
    '#28654b',
    '#28654a',
    '#27654a',
    '#27664a',
    '#27664a',
    '#27664a',
    '#27664a',
    '#26684a',
    '#26684a',
    '#26684a',
    '#26684a',
    '#26694a',
    '#25694a',
    '#25694a',
    '#25694a',
    '#256a4a',
    '#256a4a',
    '#256a4a',
    '#256a4a',
    '#246a4a',
    '#246b49',
    '#246b49',
    '#246b49',
    '#246b49',
    '#246c49',
    '#246c49',
    '#236c49',
    '#236c49',
    '#236e49',
    '#236e49',
    '#236e49',
    '#236e49',
    '#236f49',
    '#236f49',
    '#236f49',
    '#226f49',
    '#227049',
    '#227049',
    '#227049',
    '#227149',
    '#227149',
    '#227149',
    '#227149',
    '#227349',
    '#217349',
    '#217349',
    '#217449',
    '#217449',
    '#217449',
    '#217449',
    '#217549',
    '#217549',
    '#217549',
    '#207649',
    '#207649',
    '#207649',
    '#207847',
    '#207847',
    '#207847',
    '#207947',
    '#207947',
    '#207947',
    '#207947',
    '#207947',
    '#207947',
    '#207b47',
    '#207b47',
    '#207b47',
    '#207c47',
    '#207c47',
    '#207c47',
    '#207d47',
    '#207d47',
    '#207e48',
    '#207e48',
    '#207e48',
    '#208048',
    '#208048',
    '#208048',
    '#208148',
    '#208148',
    '#208148',
    '#208248',
    '#1f8247',
    '#1f8247',
    '#1f8347',
    '#1f8347',
    '#1f8347',
    '#1f8547',
    '#1f8547',
    '#1f8547',
    '#1f8647',
    '#1f8647',
    '#1f8647',
    '#1e8747',
    '#1e8747',
    '#1e8747',
    '#1e8747',
    '#1e8947',
    '#1e8947',
    '#1e8947',
    '#1e8a47',
    '#1e8a47',
    '#1e8a47',
    '#1e8b47',
    '#1e8b47',
    '#1e8b47',
    '#1e8b47',
    '#1e8c47',
    '#1e8c47',
    '#1e8c47',
    '#1e8c47',
    '#1e8e47',
    '#1e8e47',
    '#1e8e47',
    '#1e8f47',
    '#1d8f47',
    '#1d8f47',
    '#1d8f47',
    '#1d9047',
    '#1d9047',
    '#1e9047',
    '#1e9047',
    '#1e9147',
    '#1e9147',
    '#1e9147',
    '#1e9347',
    '#1e9347',
    '#1e9347',
    '#1e9347',
    '#1e9447',
    '#1e9447',
    '#1e9447',
    '#1e9548',
    '#1e9548',
    '#1e9548',
    '#1e9648',
    '#1e9648',
    '#1e9648',
    '#1f9848',
    '#1f9848',
    '#1f9848',
    '#209948',
    '#209948',
    '#209948',
    '#209a48',
    '#209a48',
    '#209a48',
    '#209b48',
    '#209b48',
    '#209b48',
    '#209b48',
    '#219c48',
    '#219c48',
    '#219d48',
    '#219d48',
    '#219e48',
    '#219e48',
    '#219e48',
    '#219f48',
    '#219f48',
    '#21a048',
    '#21a048',
    '#21a048',
    '#21a148',
    '#21a148',
    '#21a348',
    '#21a348',
    '#22a449',
    '#22a449',
    '#22a449',
    '#22a549',
    '#22a549',
    '#22a649',
    '#22a649',
    '#22a64a',
    '#23a74a',
    '#23a74a',
    '#23a84a',
    '#23a84a',
    '#23aa4a',
    '#23aa4a',
    '#23aa4a',
    '#24ab4a',
    '#24ab4a',
    '#24ab4a',
    '#25ac4a',
    '#25ac4a',
    '#25ad4a',
    '#25ad4a',
    '#25ad4a',
    '#26ae4a',
    '#26ae4a',
    '#26ae4a',
    '#26af4a',
    '#26af4a',
    '#26b04a',
    '#26b04a',
    '#26b04a',
    '#27b14a',
    '#27b14a',
    '#27b14a',
    '#27b24a',
    '#27b24a',
    '#27b24a',
    '#27b24a',
    '#27b34a',
    '#27b34a',
    '#27b34a',
    '#28b54b',
    '#28b54b',
    '#28b54b',
    '#28b54b',
    '#28b64b',
    '#28b64b',
    '#28b64d',
    '#28b64d',
    '#28b74d',
    '#28b74d',
    '#28b74d',
    '#29b74d',
    '#29b84d',
    '#29b84d',
    '#29b84d',
    '#29b84d',
    '#29b94d',
    '#29b94d',
    '#29b94d',
    '#29b94d',
    '#29b94d',
    '#29b94d',
    '#29b94d',
    '#29b94e',
    '#2aba4e',
    '#2aba4e',
    '#2aba4e',
    '#2aba4e',
    '#2aba4e',
    '#2aba4e',
    '#2aba4e',
    '#2abb4e',
    '#2abb4e',
    '#2abb4f',
    '#2abb4f',
    '#2abc4f',
    '#2abc4f',
    '#2abc4f',
    '#2bbc4f',
    '#2bbc4f',
    '#2bbd4f',
    '#2bbd4f',
    '#2bbd50',
    '#2bbd50',
    '#2bbd50',
    '#2bbe50',
    '#2bbe50',
    '#2bbe50',
    '#2bbe50',
    '#2bbe50',
    '#2bbf51',
    '#2cbf51',
    '#2cbf51',
    '#2cbf51',
    '#2cc051',
    '#2cc051',
    '#2cc051',
    '#2cc052',
    '#2cc052',
    '#2cc152',
    '#2cc152',
    '#2cc152',
    '#2cc152',
    '#2dc353',
    '#2dc353',
    '#2dc353',
    '#2dc353',
    '#2dc353',
    '#2ec453',
    '#2ec453',
    '#2ec454',
    '#2ec454',
    '#2ec454',
    '#2ec554',
    '#2ec554',
    '#2fc554',
    '#2fc554',
    '#2fc655',
    '#2fc655',
    '#2fc655',
    '#2fc655',
    '#2fc655',
    '#2fc755',
    '#2fc755',
    '#2fc756',
    '#2fc756',
    '#2fc756',
    '#2fc756',
    '#2fc856',
    '#2fc856',
    '#2fc856',
    '#2fc856',
    '#2fc856',
    '#2fc858',
    '#2fc958',
    '#2fc958',
    '#2fc958',
    '#2fc958',
    '#2fc958',
    '#2fc958',
    '#2fc958',
    '#2fca58',
    '#2fca58',
    '#2fca58',
    '#2fca59',
    '#2fca59',
    '#2fca59',
    '#2fca59',
    '#2fca59',
    '#30ca59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb59',
    '#30cb5a',
    '#30cb5a',
    '#30cb5a',
    '#30cb5a',
    '#30cb5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
    '#30cc5a',
  ],
  nullColor: 'pink',
}
const blueGradientBubbles = {
  colors: [
    '#0c3574',
    '#0b3475',
    '#0b3475',
    '#0c3475',
    '#0c3575',
    '#0c3475',
    '#0c3475',
    '#0c3576',
    '#0c3677',
    '#0c3677',
    '#0d3776',
    '#0d3676',
    '#0e3677',
    '#0e3576',
    '#0d3576',
    '#0d3677',
    '#0d3777',
    '#0e3778',
    '#0e3778',
    '#0d3877',
    '#0d3878',
    '#0d3878',
    '#0d3878',
    '#0d3878',
    '#0d3978',
    '#0e3778',
    '#0e3779',
    '#0e397a',
    '#0f3979',
    '#0f3a7a',
    '#0f3a7b',
    '#0f3a7b',
    '#0e3a7a',
    '#0e3a7a',
    '#0f3a7a',
    '#0e3a7a',
    '#0f3a7c',
    '#0f3a7c',
    '#0f3a7b',
    '#0f3b7b',
    '#0f3a7b',
    '#0e3a7b',
    '#0e3b7c',
    '#0f3c7c',
    '#0f3c7c',
    '#0f3d7d',
    '#0f3d7d',
    '#0f3c7d',
    '#0f3c7e',
    '#0f3d7e',
    '#0f3d7e',
    '#0f3d7e',
    '#0f3e7e',
    '#0f3d7e',
    '#103e7e',
    '#113e7f',
    '#103e7e',
    '#103d7e',
    '#103e7f',
    '#103e7e',
    '#113e80',
    '#113f80',
    '#103f7f',
    '#114080',
    '#113f81',
    '#114081',
    '#113f81',
    '#103e80',
    '#103f81',
    '#104181',
    '#104181',
    '#103f81',
    '#114082',
    '#103f82',
    '#103f81',
    '#114081',
    '#114282',
    '#114282',
    '#114282',
    '#124182',
    '#114082',
    '#114182',
    '#114283',
    '#124384',
    '#114384',
    '#114383',
    '#124383',
    '#124485',
    '#114384',
    '#114384',
    '#114384',
    '#114484',
    '#124585',
    '#124585',
    '#124585',
    '#134587',
    '#134586',
    '#134586',
    '#134687',
    '#134687',
    '#134586',
    '#134686',
    '#134687',
    '#134787',
    '#134788',
    '#134688',
    '#134688',
    '#134687',
    '#124788',
    '#124789',
    '#134688',
    '#134688',
    '#134688',
    '#134788',
    '#134889',
    '#13488a',
    '#13488a',
    '#13488a',
    '#134889',
    '#134789',
    '#13478a',
    '#13498a',
    '#14498a',
    '#15498b',
    '#14488b',
    '#14498c',
    '#15498c',
    '#14498b',
    '#15498c',
    '#15498c',
    '#14498c',
    '#154a8d',
    '#144b8c',
    '#144b8c',
    '#154b8d',
    '#144c8d',
    '#154b8d',
    '#154b8d',
    '#154c8d',
    '#144c8d',
    '#154c8d',
    '#154c8d',
    '#164c8e',
    '#164c8e',
    '#164c8e',
    '#164c8e',
    '#154d8e',
    '#154d8f',
    '#164e90',
    '#164e90',
    '#164e90',
    '#154e8f',
    '#154d90',
    '#154e90',
    '#164e90',
    '#154e91',
    '#154f91',
    '#155091',
    '#165091',
    '#164f91',
    '#164f92',
    '#165092',
    '#165092',
    '#165092',
    '#165092',
    '#155192',
    '#155192',
    '#175093',
    '#175293',
    '#175293',
    '#175093',
    '#175093',
    '#175293',
    '#175293',
    '#175193',
    '#175294',
    '#175394',
    '#175394',
    '#175294',
    '#175395',
    '#175295',
    '#175295',
    '#175395',
    '#175395',
    '#185495',
    '#185495',
    '#175396',
    '#175497',
    '#175397',
    '#175497',
    '#175597',
    '#175597',
    '#175597',
    '#175497',
    '#185497',
    '#195698',
    '#195798',
    '#185597',
    '#185497',
    '#185599',
    '#195799',
    '#195799',
    '#185799',
    '#185699',
    '#195699',
    '#195799',
    '#195799',
    '#19589a',
    '#185899',
    '#185899',
    '#18589a',
    '#18589a',
    '#18599a',
    '#18589a',
    '#18579a',
    '#19589b',
    '#19599b',
    '#19599a',
    '#18599a',
    '#1a5a9c',
    '#1a5a9d',
    '#1a5a9c',
    '#1a599d',
    '#1a5a9d',
    '#1a5a9e',
    '#1a5a9e',
    '#195a9d',
    '#195a9d',
    '#195a9d',
    '#195a9d',
    '#1a5a9e',
    '#1a5b9f',
    '#1a5b9e',
    '#1a5b9e',
    '#1a5d9e',
    '#1a5c9f',
    '#195c9f',
    '#195c9f',
    '#195da0',
    '#195d9f',
    '#195ca0',
    '#1a5ca0',
    '#1a5d9f',
    '#1a5ea1',
    '#1a5ea1',
    '#1a5da0',
    '#1a5da0',
    '#195ea0',
    '#195ea0',
    '#1a5ea0',
    '#1a5ea1',
    '#1c5fa3',
    '#1c5fa3',
    '#1b5fa3',
    '#1b5ea2',
    '#1b5ea2',
    '#1b5ea2',
    '#1b5fa3',
    '#1b60a3',
    '#1b60a3',
    '#1b5fa3',
    '#1b60a3',
    '#1c61a4',
    '#1b60a4',
    '#1b60a4',
    '#1b61a4',
    '#1b60a4',
    '#1b60a4',
    '#1b60a4',
    '#1b62a4',
    '#1b62a5',
    '#1b61a5',
    '#1b62a5',
    '#1b62a5',
    '#1b61a5',
    '#1d61a6',
    '#1d63a6',
    '#1c62a6',
    '#1d63a6',
    '#1d62a6',
    '#1c62a6',
    '#1c63a7',
    '#1d64a8',
    '#1c64a7',
    '#1c64a7',
    '#1d65a8',
    '#1c63a8',
    '#1d64a9',
    '#1d65a9',
    '#1d65a9',
    '#1d65a9',
    '#1d65a9',
    '#1d66a9',
    '#1d66a9',
    '#1c65a9',
    '#1d66aa',
    '#1d66aa',
    '#1c65aa',
    '#1c66aa',
    '#1c66aa',
    '#1d66aa',
    '#1c66ab',
    '#1c68ab',
    '#1c67ab',
    '#1c67ab',
    '#1d68ab',
    '#1d68ac',
    '#1e68ac',
    '#1e67ab',
    '#1d67ab',
    '#1e69ac',
    '#1e69ac',
    '#1d68ac',
    '#1d68ac',
    '#1e69ae',
    '#1e6aae',
    '#1e6aad',
    '#1e69ad',
    '#1e6aae',
    '#1d69ae',
    '#1d6aae',
    '#1e6bae',
    '#1e6aaf',
    '#1e6baf',
    '#1e6baf',
    '#1e6bb0',
    '#1e6cb0',
    '#1d6baf',
    '#1d6baf',
    '#1e6bb0',
    '#1d6ab0',
    '#1d6cb0',
    '#1d6db1',
    '#1e6cb1',
    '#1e6db0',
    '#1e6cb1',
    '#1d6cb1',
    '#1d6cb0',
    '#1f6db1',
    '#1f6cb1',
    '#1f6cb1',
    '#1f6db2',
    '#1f6cb1',
    '#1f6db1',
    '#1f6eb3',
    '#1f6fb3',
    '#1f6eb3',
    '#1f6eb3',
    '#1f6fb3',
    '#1f6fb3',
    '#1f6fb4',
    '#1f6fb4',
    '#1f6fb4',
    '#2070b5',
    '#1f70b4',
    '#1f70b4',
    '#1f70b4',
    '#1f71b5',
    '#1f71b5',
    '#1f71b5',
    '#1f71b5',
    '#1f70b5',
    '#1f71b5',
    '#1f72b6',
    '#1f72b6',
    '#1f71b6',
    '#1f71b6',
    '#1f72b5',
    '#2072b6',
    '#2172b7',
    '#2172b7',
    '#2172b6',
    '#2172b6',
    '#2172b7',
    '#2072b7',
    '#2073b8',
    '#2173b9',
    '#2173b9',
    '#2075b8',
    '#2075b8',
    '#2174b8',
    '#2174b9',
    '#2175ba',
    '#2174ba',
    '#2174ba',
    '#2175ba',
    '#2175ba',
    '#2176bb',
    '#2176bb',
    '#2076ba',
    '#2076ba',
    '#2176bb',
    '#2176bb',
    '#2076bb',
    '#2077bb',
    '#2177bc',
    '#2077bb',
    '#2077bb',
    '#2077bc',
    '#2077bc',
    '#2077bc',
    '#2177bd',
    '#2078bc',
    '#2078bc',
    '#2178bd',
    '#2279be',
    '#2178be',
    '#2179be',
    '#2179be',
    '#2279bf',
    '#2278bf',
    '#2179bf',
    '#217abf',
    '#227ac0',
    '#2279c0',
    '#227ac0',
    '#227abf',
    '#227ac0',
    '#217bc0',
    '#227bc0',
    '#227bc0',
    '#217bc0',
    '#217ac0',
    '#217bc0',
    '#217cc1',
    '#227cc1',
    '#217cc1',
    '#217cc1',
    '#227cc1',
    '#217cc1',
    '#227cc2',
    '#227cc2',
    '#227dc2',
    '#217dc1',
    '#217dc2',
    '#217dc2',
    '#217dc3',
    '#217ec3',
    '#217ec3',
    '#217ec3',
    '#227ec4',
    '#237fc4',
    '#237ec5',
    '#237ec5',
    '#237ec5',
    '#237ec4',
    '#237ec4',
    '#237ec6',
    '#237fc6',
    '#2380c6',
    '#237fc5',
    '#237fc5',
    '#2380c5',
    '#2380c7',
    '#2381c6',
    '#2381c6',
    '#2380c6',
    '#2381c7',
    '#2380c6',
    '#2380c7',
    '#2381c8',
    '#2382c7',
    '#2381c7',
    '#2381c7',
    '#2382c8',
    '#2382c8',
    '#2383c8',
    '#2384c9',
    '#2384c9',
    '#2383c8',
    '#2383c9',
    '#2383ca',
    '#2384ca',
    '#2384ca',
    '#2383ca',
    '#2383ca',
    '#2384ca',
    '#2384ca',
    '#2484cb',
    '#2485cb',
    '#2385cb',
    '#2384cb',
    '#2385cb',
    '#2485cb',
    '#2585cc',
    '#2586cc',
    '#2485cc',
    '#2485cc',
    '#2485cc',
    '#2486cd',
    '#2586cd',
    '#2587cd',
    '#2487cc',
    '#2486cd',
    '#2587cd',
    '#2587cd',
    '#2487ce',
    '#2588ce',
    '#2588ce',
    '#2588ce',
    '#2488cf',
    '#2588cf',
    '#2589ce',
    '#2589ce',
    '#2688cf',
    '#2589ce',
    '#2789cf',
    '#2889d0',
    '#2889d0',
    '#288ad0',
    '#2889d0',
    '#298ad0',
    '#2b8bd0',
    '#2a89cf',
    '#2989cf',
    '#2b8bcf',
    '#2c8cd0',
    '#2c8ccf',
    '#2d8cd0',
    '#2d8cd0',
    '#2c8bd0',
    '#2e8cd1',
    '#2e8cd1',
    '#2e8cd0',
    '#2e8cd1',
    '#2e8dd0',
    '#308ed0',
    '#318ed1',
    '#318dd1',
    '#308dd0',
    '#308dd0',
    '#318fd0',
    '#338fd1',
    '#328dd1',
    '#338ed1',
    '#348fd1',
    '#348fd2',
    '#338ed2',
    '#338ed1',
    '#348fd1',
    '#3590d1',
    '#368fd2',
    '#3690d1',
    '#3690d1',
    '#3690d2',
    '#3791d3',
    '#3791d3',
    '#3791d2',
    '#3790d2',
    '#3790d2',
    '#3892d2',
    '#3892d2',
    '#3992d2',
    '#3a92d3',
    '#3a93d3',
    '#3b92d3',
    '#3b92d3',
    '#3a93d3',
    '#3a93d3',
    '#3b93d3',
    '#3c94d3',
    '#3d94d3',
    '#3d94d3',
    '#3d94d3',
    '#3d93d3',
    '#3f93d4',
    '#3f96d4',
    '#3f96d4',
    '#3e95d3',
    '#3e96d3',
    '#3f96d4',
    '#4095d4',
    '#4095d4',
    '#3f96d4',
    '#3f96d4',
    '#4096d4',
    '#4297d4',
    '#4197d4',
    '#4096d3',
    '#4196d3',
    '#4397d5',
    '#4398d5',
    '#4397d5',
    '#4398d5',
    '#4398d5',
    '#4499d5',
    '#4499d5',
    '#4499d5',
    '#4499d5',
    '#459ad5',
    '#459ad4',
    '#459ad4',
    '#459ad4',
    '#459ad5',
    '#459ad6',
    '#469bd6',
    '#469bd5',
    '#469ad5',
    '#469ad5',
    '#479bd5',
    '#499cd6',
    '#499cd6',
    '#499cd5',
    '#499cd5',
    '#499cd6',
    '#4a9dd6',
    '#4a9fd6',
    '#4a9ed6',
    '#4a9ed5',
    '#4a9ed6',
    '#4b9ed7',
    '#4b9ed7',
    '#4b9fd7',
    '#4a9ed7',
    '#4b9ed7',
    '#4ca0d7',
    '#4c9ed7',
    '#4b9ed7',
    '#4c9fd7',
    '#4da0d7',
    '#4da0d8',
    '#4da0d8',
    '#4c9fd8',
    '#4c9fd7',
    '#4da0d7',
    '#4ea2d8',
    '#4ea2d8',
    '#4ea1d8',
    '#4ea2d7',
    '#4ea2d7',
    '#4ea2d8',
    '#4ea2d7',
    '#4ea2d7',
    '#4fa3d9',
    '#50a3d9',
    '#50a3d8',
    '#4fa2d8',
    '#4fa3d8',
    '#51a3d8',
    '#51a3d8',
    '#51a3d9',
    '#51a3d8',
    '#51a3d8',
    '#52a4d9',
    '#52a5da',
    '#52a4d9',
    '#52a4d9',
    '#52a5da',
    '#53a5da',
    '#53a5da',
    '#53a5da',
    '#54a5da',
    '#54a5da',
    '#54a6d9',
    '#54a7d9',
    '#54a6d9',
    '#54a7d9',
    '#55a8da',
    '#55a8da',
    '#56a8db',
    '#55a7da',
    '#56a8db',
    '#57a8db',
    '#56a7da',
    '#57a8db',
    '#57a8db',
    '#57a8db',
    '#58a9da',
    '#58aada',
    '#57a9da',
    '#57a8da',
    '#57a9da',
    '#58aadb',
    '#59aadb',
    '#59aadb',
    '#59aadc',
    '#59abdb',
    '#5aacdb',
    '#5aabdb',
    '#59abdb',
    '#5babdb',
    '#5babdc',
    '#5babdc',
    '#5aabdc',
    '#5baddc',
    '#5bacdc',
    '#5cacdc',
    '#5bacdc',
    '#5caedc',
    '#5caddc',
    '#5cacdc',
    '#5caddc',
    '#5dafdc',
    '#5dafdc',
    '#5caedc',
    '#5daedc',
    '#5eb0dd',
    '#5db0dd',
    '#5eafdd',
    '#5eafdd',
    '#5fb1dd',
    '#5fb1dd',
    '#5fafdd',
    '#5fafdd',
    '#60b1dd',
    '#60b1dd',
    '#60b1dd',
    '#60b1dd',
    '#60b1dd',
    '#61b1de',
    '#61b1de',
    '#61b1de',
    '#61b1de',
    '#61b2dd',
    '#61b2dd',
    '#62b2dd',
    '#62b2de',
    '#62b2de',
    '#62b2de',
    '#63b3dd',
    '#63b4de',
    '#63b4df',
    '#63b4df',
    '#63b3df',
    '#63b4de',
    '#63b4de',
    '#63b4df',
    '#63b5df',
    '#64b6df',
    '#64b6df',
    '#64b6df',
    '#64b5de',
    '#65b6e0',
    '#65b7e0',
    '#65b7df',
    '#65b6e0',
    '#65b6e0',
    '#65b6df',
    '#66b6df',
    '#67b7e0',
    '#67b8e0',
    '#66b8df',
    '#66b8e0',
    '#67b8df',
    '#67b7df',
    '#67b8e0',
    '#67b9e0',
    '#67b8e0',
    '#68b9e1',
    '#69bae1',
    '#69bae1',
    '#68b8e0',
    '#69b9e0',
    '#69bae0',
    '#69bae0',
    '#6abbe1',
    '#69bbe0',
    '#69bbe0',
    '#69bbe1',
    '#6abce1',
    '#6abbe0',
    '#6abbe0',
    '#6abbe1',
    '#6abce1',
    '#6abce2',
    '#6bbde2',
    '#6bbee1',
    '#6cbde2',
    '#6cbde2',
    '#6bbde1',
    '#6cbde1',
    '#6cbde2',
    '#6cbde2',
    '#6cbde2',
    '#6cbee2',
    '#6cbee2',
    '#6dbfe2',
    '#6dbfe2',
    '#6dbee2',
    '#6ebee3',
    '#6fbfe3',
    '#6fc0e3',
    '#6fc0e2',
    '#6ebfe3',
    '#6ec0e3',
    '#6ec1e3',
    '#6ec1e3',
    '#6ec1e4',
    '#6fc0e3',
    '#70c1e3',
    '#70c2e3',
    '#70c2e4',
    '#6fc1e3',
    '#6fc1e3',
    '#70c2e3',
    '#71c2e4',
    '#70c3e4',
    '#70c2e4',
    '#70c2e4',
    '#72c4e4',
    '#72c4e4',
    '#71c2e4',
    '#71c2e4',
    '#73c4e4',
    '#72c3e4',
    '#72c3e4',
    '#72c3e4',
    '#72c4e4',
    '#73c5e4',
    '#73c5e4',
    '#72c5e4',
    '#73c6e4',
    '#73c5e4',
    '#73c6e4',
    '#74c7e5',
    '#73c6e4',
    '#75c8e5',
    '#74c7e5',
    '#74c7e5',
    '#74c6e5',
    '#75c6e5',
    '#76c7e5',
    '#75c7e5',
    '#76c8e5',
    '#75c8e5',
    '#75c8e6',
    '#76c8e6',
    '#77c8e5',
    '#76c9e6',
    '#76c9e5',
    '#76c9e5',
    '#77cae6',
    '#77c9e6',
    '#77c9e6',
    '#77c9e6',
    '#78cae6',
    '#77cbe7',
    '#77cbe7',
    '#78cbe7',
    '#78cbe7',
    '#79cae6',
    '#78cae6',
    '#78cbe7',
    '#78cae6',
    '#79cae6',
    '#7acce6',
    '#7acde7',
    '#7acbe7',
    '#79cce6',
    '#79cee7',
    '#7acee8',
    '#7acde8',
    '#79cde7',
    '#7acde7',
    '#7bcee8',
    '#7acee7',
    '#7acfe7',
    '#7acee7',
    '#7ccee8',
    '#7bcee7',
    '#7bcfe7',
    '#7bcfe7',
    '#7bcfe8',
    '#7dd0e9',
    '#7dd0e9',
    '#7ccfe8',
    '#7ccfe8',
    '#7cd1e8',
    '#7cd1e8',
    '#7cd0e8',
    '#7cd0e8',
    '#7dd1e8',
    '#7dd2e9',
    '#7dd2e9',
    '#7ed2e9',
    '#7dd1e8',
    '#7dd2e8',
    '#7fd3ea',
    '#7fd3ea',
    '#7ed3e9',
    '#7ed2ea',
    '#80d2ea',
    '#7fd3e9',
    '#7fd2e9',
    '#80d4ea',
    '#80d5ea',
    '#7fd4e9',
    '#7fd4e9',
    '#80d4e9',
    '#81d6ea',
    '#81d6eb',
    '#80d4ea',
    '#80d5ea',
    '#80d6ea',
    '#80d5ea',
    '#81d6ea',
    '#81d6ea',
    '#82d6ea',
    '#82d7ea',
    '#82d8ea',
    '#81d6ea',
    '#81d6ea',
    '#82d8ea',
    '#82d7eb',
    '#82d8eb',
    '#82d8eb',
    '#82d7eb',
    '#83d9eb',
    '#84d9ec',
    '#84d9ec',
    '#83d8eb',
    '#83d8eb',
    '#85d8eb',
    '#85d9eb',
    '#84daeb',
    '#85daeb',
    '#85dbec',
    '#85dbec',
    '#84daec',
    '#85dbec',
    '#86dbec',
    '#86dbec',
    '#85dbec',
    '#85dbec',
    '#85dbec',
    '#85dcec',
    '#86ddec',
    '#86dceb',
    '#86dceb',
    '#87ddec',
    '#86dced',
    '#86dcec',
    '#86deec',
    '#86deed',
    '#87ddec',
    '#87deed',
    '#87dfec',
    '#87deec',
    '#88deed',
    '#89deec',
    '#88deec',
    '#88dfed',
    '#88dfec',
    '#89e0ed',
    '#89e0ee',
    '#88dfee',
    '#89e0ee',
    '#88dfed',
    '#88dfed',
    '#8ae1ee',
    '#89e1ed',
    '#8ae2ee',
    '#8ae2ee',
    '#8ae2ee',
    '#8ae1ee',
    '#8be3ee',
    '#8be3ee',
    '#8be2ee',
    '#8be2ee',
    '#8be2ef',
    '#8ce2ef',
    '#8ce3ef',
    '#8be3ee',
    '#8ce4ef',
    '#8be3ef',
    '#8be3ee',
    '#8de5ef',
    '#8de4ef',
    '#8de4ef',
    '#8de5ef',
    '#8ce4ef',
    '#8de4ef',
    '#8de4ef',
    '#8de4f0',
    '#8ee6f0',
    '#8ee6f0',
    '#8ee6ef',
    '#8ee6ef',
    '#8de7ef',
    '#8de6ef',
    '#8fe7f0',
    '#8fe8f1',
    '#8ee6ef',
    '#8ee6ef',
    '#8ee8f1',
    '#8fe8f1',
    '#8fe7f0',
    '#90e8f1',
    '#90e8f1',
    '#8fe8f0',
    '#90eaf1',
    '#8fe9f0',
    '#8fe9f0',
    '#90eaf1',
    '#90eaf1',
    '#91eaf1',
    '#91eaf1',
    '#91eaf1',
    '#92ebf2',
    '#91eaf1',
  ],
  nullColor: 'pink',
}

export const gradients: Record<ScaleId, GradientDefinition> = {
  [ScaleId.IntradayReduced]: redGreenGradient,
  [ScaleId.DayPerf]: redGreenGradient,
  _5: redGreenGradient,
  _10: redGreenGradient,
  _15: redGreenGradient,
  _20: redGreenGradient,
  _25: redGreenGradient,
  _relvol: blueGradient,
  _pe: greenRedGradient,
  _fpe: greenRedGradient,
  _peg: greenRedGradient,
  _ps: greenRedGradient,
  _pb: greenRedGradient,
  _div: greenGradient,
  _eps5y: redGreenGradient,
  _short: redGradient,
  _rec: redGreenGradient,
  _earndate: earningsGradient,
  _portfolioPct: redGreenGradient,
  _portfolioUsd: redGreenGradient,
}

export const bubbleGradients: Record<ScaleId, GradientDefinition> = {
  [ScaleId.IntradayReduced]: redGreenGradientBubbles,
  [ScaleId.DayPerf]: redGreenGradientBubbles,
  _5: redGreenGradientBubbles,
  _10: redGreenGradientBubbles,
  _15: redGreenGradientBubbles,
  _20: redGreenGradientBubbles,
  _25: redGreenGradientBubbles,
  _relvol: blueGradientBubbles,
  _pe: redGreenGradientBubbles,
  _fpe: redGreenGradientBubbles,
  _peg: redGreenGradientBubbles,
  _ps: redGreenGradientBubbles,
  _pb: redGreenGradientBubbles,
  _div: redGreenGradientBubbles,
  _eps5y: redGreenGradientBubbles,
  _short: redGreenGradientBubbles, // TODO red skala
  _rec: redGreenGradientBubbles,
  _earndate: redGreenGradientBubbles,
  _portfolioPct: redGreenGradientBubbles,
  _portfolioUsd: redGreenGradientBubbles,
}

export const gradientSmall = redGreenGradientSmall
