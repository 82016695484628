import { link } from '../services/routing'
import { Delayed, DelayedProps } from './delayed'
import { ErrorView } from './error-view'
import { Spinner } from './spinner'

type LoadingProps = Pick<DelayedProps, 'delay' | 'timeout' | 'throwOnTimeout'>

export function Loading({ delay, timeout, throwOnTimeout }: LoadingProps) {
  return (
    <Delayed
      delay={delay}
      timeout={timeout}
      delayComponent={null}
      throwOnTimeout={throwOnTimeout}
      timeoutComponent={
        <ErrorView
          showContact={false}
          title="Loading error"
          message={
            <>
              There’s been an error loading the application.
              <br />
              Please make sure your internet connection is stable and refresh the page. If the error persists, please{' '}
              <a href={link.toContact()} className="text-blue-600 underline hover:no-underline">
                contact support
              </a>
              .
            </>
          }
        />
      }
    >
      <Spinner />
    </Delayed>
  )
}

export function LoadingScreen(props: LoadingProps) {
  return (
    <div className="flex max-h-screen min-h-64 grow items-center justify-center">
      <Loading {...props} />
    </div>
  )
}
