import classnames from 'classnames'
import classNames from 'classnames'
import * as React from 'react'

import { Select, SelectListProps, SelectProps } from '../../../main/components'
import { ButtonHTMLProps, ButtonProps } from '../../../main/components/button'
import { Icon } from '../../../main/components/icon'
import { isSelectItemGroup } from '../../../main/components/select/utils'

export enum MapSelectTheme {
  light,
  dark,
}

interface MapSelectThemeReturn {
  label: React.HTMLProps<HTMLHeadingElement>
  button: ButtonProps & ButtonHTMLProps
  popover: SelectListProps
}

const hasRedesign = FinvizSettings.hasRedesignEnabled

function getMapSelectTheme(theme: MapSelectTheme): MapSelectThemeReturn | undefined {
  switch (theme) {
    case MapSelectTheme.dark:
      return {
        label: {
          className: 'text-gray-400',
        },
        button: {
          theme: 'transparent',
          rounding: 'none',
          className: 'w-full border border-[#585f72] bg-[#4a505f] hover:border-[#707990] hover:bg-[#535a6b]',
          rightContent: <Icon name="caretDown" />,
        },
        popover: {
          rounding: 'none',
          theme: 'none',
          className:
            'border border-[#585f72] bg-[#4a505f] [--bg-primary:74_80_95] [--border-primary:103,111,133] [--scrollbar-radius:0] dark:[--thumb-bg-hover:#262931] dark:[--thumb-bg:#363a46] dark:[--track-bg-hover:transparent] dark:[--track-bg:transparent]',
        },
      }

    case MapSelectTheme.light:
      return {
        label: { className: 'text-gray-500' },
        button: {
          rounding: 'none',
          className: 'w-full bg-primary',
        },
        popover: {
          rounding: 'none',
          className: classnames('overflow-y-auto max-h-80', {
            '[--scrollbar-radius:0]': !hasRedesign,
          }),
        },
      }
  }
}

interface MapSelectProps<ValueType, ItemType, Name> extends Omit<SelectProps<ValueType, ItemType, Name>, 'theme'> {
  /**
   * Theme for the map select
   *
   * @default dark
   */
  theme?: keyof typeof MapSelectTheme
}

export function MapSelect<ValueType, ItemType, Name>({
  label,
  'aria-label': ariaLabel,
  theme = 'dark',
  items,
  onChange,
  ...props
}: MapSelectProps<ValueType, ItemType, Name>) {
  const selectTheme = React.useMemo(() => getMapSelectTheme(MapSelectTheme[theme]), [theme])
  const itemOptions = React.useMemo(
    () =>
      items?.map((item) => {
        if (isSelectItemGroup(item)) {
          return {
            ...item,
            hasBorder: false,
            className: 'border-t border-gray-100 dark:border-gray-700',
          }
        }

        return item
      }),
    [items]
  )

  return (
    <div>
      {label && (
        <h3 className={classnames(selectTheme?.label.className, 'm-0 mb-1 text-3xs uppercase font-bold')}>{label}</h3>
      )}
      <Select
        {...props}
        {...selectTheme?.button}
        value={props.value}
        rounding={props.rounding ?? selectTheme?.button.rounding}
        size={props.size ?? 'medium'}
        aria-label={ariaLabel ?? (typeof label === 'string' ? label : undefined)}
        placeholder="Any"
        name={props.name}
        onChange={onChange}
        defaultValue={undefined}
        items={itemOptions}
        listProps={{
          ...selectTheme?.popover,
          ...props.listProps,
          className: classNames(selectTheme?.popover.className, props.listProps?.className),
        }}
      />
    </div>
  )
}
